<template>
  <Dropdown
    ref="dropdown"
    class="list-dropdown"
    :opened="opened"
    :class="containerClass"
    :disabled="disabled"
    :custom-tab-index="customTabIndex"
    :variant="variant"
    @open="onOpen"
    @close="onClose"
    @clickaway="onClickaway"
  >
    <template #caption>
      <slot
        v-if="labelOnSelection && hasSelection"
        name="selection"
        :selection="selection"
      >
        {{ selectedLabels }}
      </slot>
      <slot
        v-else
        name="placeholder"
      >
        {{ placeholder }}
      </slot>
    </template>

    <div class="list-dropdown__content">
      <ul
        ref="listDropdownItems"
        class="list-dropdown__items"
      >
        <DropdownItem
          v-if="includeBlank"
          :value="null"
          :selected="!hasSelection"
          :label="includeBlank"
          :url="buildItemUrl(null)"
          :follow-url="followItemUrl"
          class="list-dropdown__item--blank"
          @click="selectAndMaybeChange"
        />

        <DropdownItem
          v-for="(option, index) in options"
          :key="index"
          :label="option.label"
          :value="option.value"
          :count="option.count"
          :indentation="option.depth"
          :selected="isSelected(option.value)"
          :url="buildItemUrl(option.value)"
          :follow-url="followItemUrl"
          class="list-dropdown__item--value"
          @click="selectAndMaybeChange"
        >
          <template #beforeLabel>
            <slot
              name="beforeLabel"
              :option="option"
            />
          </template>
        </DropdownItem>
      </ul>

      <SvgIcon
        :image="arrowDown"
        class="icon list-dropdown__arrow-indicator list-dropdown__arrow-indicator--up"
        :class="arrowIndicatorDownContainerClass"
      />
      <SvgIcon
        :image="arrowUp"
        class="icon list-dropdown__arrow-indicator list-dropdown__arrow-indicator--down"
        :class="arrowIndicatorUpContainerClass"
      />

      <div
        v-if="multiple"
        class="list-dropdown__submit"
      >
        <button
          type="button"
          class="btn btn--primary"
          :disabled="!hasChanged"
          @click.prevent="change"
        >
          <slot name="buttonContent">
            {{ buttonTextOrDefault }}
          </slot>
        </button>
      </div>
    </div>
  </Dropdown>
</template>

<script>
import { isEqual, isNull, isUndefined, uniq, without, throttle } from 'lodash-es';
import Dropdown from '@/components/dropdown.vue';
import DropdownItem from '@/components/dropdown-item.vue';
import arrowDown from '@/assets/03-generic/images/arrow-down-dropdown.svg';
import arrowUp from '@/assets/03-generic/images/arrow-up-dropdown.svg';

const DEFAULT_BUTTON_TEXT = 'Übernehmen';

function sanitizeValue(value, multiple) {
  if (isUndefined(value) || isNull(value)) {
    if (multiple) return [];
    return null;
  }
  return value;
}

export default {
  name: 'ListDropdown',
  components: {
    Dropdown,
    DropdownItem,
  },
  props: {
    customTabIndex: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: null,
    },
    modelValue: {
      type: null,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    includeBlank: {
      type: String,
      default: null,
    },
    labelOnSelection: {
      type: Boolean,
      default: false,
    },
    itemUrl: {
      type: Function,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    followItemUrl: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: '',
    },
  },
  emits: [
    'open',
    'close',
    'focus',
    'select',
    'unselect',
    'toggle-selection',
    'change',
    'update:modelValue',
  ],
  data() {
    return {
      internalValue: sanitizeValue(this.modelValue, this.multiple),
      arrowUp,
      arrowDown,
      scrollableUp: false,
      scrollableDown: true,
    };
  },
  computed: {
    selection() {
      const filterFunc = (option) => this.isSelected(option.value);
      if (this.multiple) return this.options.filter(filterFunc);
      return this.options.find((option) => this.isSelected(option.value));
    },
    hasSelection() {
      if (!this.multiple) return !!this.selection;
      return this.selection && this.selection.length > 0;
    },
    selectedLabels() {
      if (!this.hasSelection) return '';
      if (!this.multiple) return this.selection.label;
      return this.selection
        .map((option) => option.label)
        .sort()
        .join(', ');
    },
    selectedValues() {
      if (this.multiple) return this.internalValue || [];
      if (this.internalValue === null) return [];
      return [this.internalValue];
    },
    hasChanged() {
      return !isEqual(this.modelValue, this.internalValue);
    },
    containerClass() {
      return {
        'dropdown--simple': !this.multiple,
        'dropdown--multi': this.multiple,
      };
    },
    buttonTextOrDefault() {
      return this.buttonText || DEFAULT_BUTTON_TEXT;
    },
    arrowIndicatorUpContainerClass() {
      return { 'list-dropdown__arrow-indicator--active': this.scrollableUp };
    },
    arrowIndicatorDownContainerClass() {
      return { 'list-dropdown__arrow-indicator--active': this.scrollableDown };
    },
  },
  watch: {
    modelValue(value) {
      this.internalValue = sanitizeValue(value, this.multiple);
    },
    opened(isOpened) {
      this.isOpened = isOpened;
    },
  },
  mounted() {
    if (this.$refs.listDropdownItems) {
      this.$refs.listDropdownItems.addEventListener(
        'scroll',
        throttle(this.handleListDropdownScroll, 200)
      );
    }

    if (window) {
      window.addEventListener('resize', throttle(this.handleWindowResize, 200));
    }
  },
  unmounted() {
    if (this.$refs.listDropdownItems) {
      this.$refs.listDropdownItems.removeEventListener(
        'scroll',
        throttle(this.handleListDropdownScroll, 200)
      );
    }

    if (window) {
      window.removeEventListener('resize', throttle(this.handleWindowResize, 200));
    }
  },
  methods: {
    handleListDropdownScroll() {
      this.updateListDropdownValues();
    },
    handleWindowResize() {
      this.updateListDropdownValues();
    },
    updateListDropdownValues() {
      const { listDropdownItems } = this.$refs;
      if (!listDropdownItems) return;

      const listDropdownItemsScrollTop = listDropdownItems.scrollTop;
      const listDropdownItemsOffsetHeight = listDropdownItems.offsetHeight;
      const listDropdownItemsScrollHeight = listDropdownItems.scrollHeight;
      const gap = 10;

      this.scrollableUp = listDropdownItemsScrollTop > gap;
      this.scrollableDown =
        listDropdownItemsScrollTop <
        listDropdownItemsScrollHeight - listDropdownItemsOffsetHeight - gap;
    },
    async onOpen() {
      await this.$nextTick();
      this.updateListDropdownValues();
      this.$emit('open');
    },
    onClose() {
      this.reset();
      this.$emit('close');
    },
    onClickaway() {
      this.onClose();
    },
    open() {
      this.$refs.dropdown.open();
    },
    close() {
      this.$refs.dropdown.close();
    },
    focus() {
      this.$refs.dropdown.focus();
      this.$emit('focus');
    },
    select(value) {
      if (this.multiple) {
        this.internalValue = uniq([...this.internalValue, value]);
      } else {
        this.internalValue = value;
      }
      this.$emit('select', value);
    },
    unselect(value) {
      if (this.multiple) {
        this.internalValue = without(this.internalValue, value);
      } else if (this.internalValue === value) {
        this.internalValue = null;
      }
      this.$emit('unselect', value);
    },
    toggleSelection(value) {
      if (this.isSelected(value)) {
        this.unselect(value);
      } else {
        this.select(value);
      }
      this.$emit('toggle-selection', value);
    },
    unselectAll() {
      if (this.multiple) {
        this.internalValue = [];
      } else {
        this.internalValue = null;
      }
    },
    isSelected(value) {
      return this.selectedValues.some((selectedValue) => isEqual(selectedValue, value));
    },
    change() {
      this.$emit('update:modelValue', this.internalValue);
      this.$emit('change', this.selection);
      this.close();
    },
    selectAndMaybeChange(value) {
      if (isUndefined(value) || isNull(value)) {
        this.unselectAll();
      } else if (this.multiple) {
        this.toggleSelection(value);
      } else {
        this.select(value);
      }
      if (!this.multiple) this.change();
    },
    reset() {
      this.internalValue = this.modelValue;
    },
    buildItemUrl(value) {
      if (!this.itemUrl) return undefined;
      return this.itemUrl(value);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/base';

.list-dropdown {
  $dropdown-border-color: #b2b2b2;

  &__items {
    display: block;
    background-color: color(white);
    border-radius: 4px;
    border: 1px solid $dropdown-border-color;
    max-height: 296px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;

    &--borderless-top {
      border-top: 0;
    }

    &--rounded-bottom {
      border-radius: 0 0 4px 4px;
    }

    &--rounded-top {
      border-radius: 4px 4px 0 0;
    }

    .dropdown--multi & {
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__submit {
    display: block;
    background-color: color(white);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid color(grey, 20000);
    padding: 12px;
    position: absolute;
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  &__content {
    position: relative;
  }

  &__arrow-indicator {
    display: block;
    height: 36px;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: auto;
    transform: translateX(-50%);
    transition-duration: 250ms;
    transition-property: opacity;
    width: 16px;
    z-index: 200;

    @keyframes heartbeat {
      0% {
        transform: translateX(-50%) scale(1);
      }

      50% {
        transform: translateX(-50%) scale(1.4);
      }

      100% {
        transform: translateX(-50%) scale(1);
      }
    }

    &--active {
      opacity: 0.3;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: heartbeat;
    }

    &--up {
      bottom: 12px;
    }

    &--down {
      top: 12px;
    }
  }
}
</style>
