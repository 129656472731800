<template>
  <li
    class="dropdown-item"
    :class="containerClass"
  >
    <a
      v-if="hasLink"
      :href="resolvedUrl"
      :rel="linkRel"
      class="dropdown-item__label"
      @click.prevent="onClick"
      @keydown.enter.prevent="onClick"
    >
      <slot
        name="beforeLabel"
        :value="value"
      />

      <DropdownItemContent :count="count">
        <slot>{{ label }}</slot>
      </DropdownItemContent>

      <slot
        name="afterLabel"
        :value="value"
      />
    </a>

    <div
      v-else
      class="dropdown-item__label"
      :tabindex="disabled ? -1 : 0"
      @click="onClick"
      @keydown.enter="onClick"
    >
      <slot
        name="beforeLabel"
        :value="value"
      />
      <DropdownItemContent :count="count">
        <slot>{{ label }}</slot>
      </DropdownItemContent>
    </div>
  </li>
</template>

<script>
import isString from 'lodash-es/isString';
import DropdownItemContent from '@/components/dropdown-item-content.vue';

export default {
  name: 'DropdownItem',
  components: {
    DropdownItemContent,
  },
  props: {
    url: {
      type: [String, Object],
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: null,
      default: null,
    },
    count: {
      type: Number,
      default: null,
    },
    indentation: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    followUrl: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  computed: {
    resolvedUrl() {
      if (!this.url) return undefined;
      if (isString(this.url)) return this.url;
      return this.$router.resolve(this.url).href;
    },
    containerClass() {
      return {
        'dropdown-item--active': this.selected,
        'dropdown-item--disabled': this.disabled,
        [`dropdown-item--indent-${this.indentation}`]: true,
      };
    },
    hasLink() {
      return !!this.url && !this.disabled;
    },
    linkRel() {
      if (this.followUrl) return undefined;
      return 'nofollow';
    },
  },
  methods: {
    onClick() {
      if (this.disabled) return;
      this.$emit('click', this.value);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/base';

$container: '.dropdown-item';
$placeholder-background: #ededed;

#{$container} {
  border-top: 1px solid color(grey, 20000);
  cursor: pointer;
  display: flex;

  &:first-of-type {
    border-top: 0;
  }

  &--borderless {
    border-top: 0;
  }

  &--disabled {
    #{$container}__label {
      &,
      &:hover,
      &:focus,
      &:active {
        cursor: not-allowed;
        opacity: 0.4;
        background-color: transparent;
      }
    }
  }

  @for $i from 1 through 8 {
    &--indent-#{$i} {
      .dropdown-item__label {
        padding-left: $i * 12px;
      }
    }
  }

  &__label {
    cursor: pointer;
    display: flex;
    @include font-size(15px, 18px);
    padding: 0;
    position: relative;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    &:hover,
    &:focus {
      background-color: $placeholder-background;
    }

    &:focus {
      outline: none;
    }

    #{$container}--active & {
      background-color: $color-primary;

      &:hover,
      &:focus {
        background-color: color(magenta, 2500);
      }
    }
  }

  &__color-preview {
    border-radius: 50%;
    display: block;
    margin-left: 12px;
    height: 12px;
    width: 12px;
    border: 1px solid color(grey, 25000);
    flex-shrink: 0;

    #{$container}--active & {
      border-color: color(magenta, 3000);
    }
  }
}
</style>
