<template>
  <div
    v-click-away="onClickaway"
    class="dropdown"
    :class="containerClass"
  >
    <button
      ref="button"
      type="button"
      class="btn dropdown__button"
      :disabled="disabled"
      :tabindex="customTabIndex"
      @click="toggle"
    >
      <slot name="caption">
        {{ caption }}
      </slot>
      <SvgIcon
        v-if="isOpened"
        :image="arrowUp"
        class="icon dropdown__icon dropdown__icon--up"
      />
      <SvgIcon
        v-else
        :image="arrowDown"
        class="icon dropdown__icon dropdown__icon--down"
      />
    </button>

    <div
      v-show="isOpened"
      class="dropdown__container"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import arrowDown from '@/assets/03-generic/images/arrow-down-dropdown.svg';
import arrowUp from '@/assets/03-generic/images/arrow-up-dropdown.svg';
import Dismissable from '@/mixins/dismissable';
import SvgIcon from '@/components/svg-icon.vue';

export default {
  name: 'Dropdown',
  components: {
    SvgIcon,
  },
  mixins: [Dismissable],
  props: {
    caption: {
      type: String,
      default: '',
    },
    opened: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    customTabIndex: {
      type: Number,
      default: 0,
    },
    variant: {
      type: String,
      default: '',
    },
  },
  emits: ['focus'],
  data() {
    return {
      arrowUp,
      arrowDown,
    };
  },
  computed: {
    containerClass() {
      return {
        'dropdown--open': this.isOpened,
        'dropdown--disabled': this.disabled,
        [`dropdown--${this.variant}`]: this.variant,
      };
    },
    isOpenable() {
      return !this.disabled;
    },
  },
  watch: {
    opened(isOpened) {
      this.isOpened = isOpened;
    },
    disabled(disabled, wasDisabled) {
      if (disabled !== wasDisabled && !disabled) {
        this.close();
      }
    },
  },
  methods: {
    focus() {
      this.$refs.button.focus();
      this.$emit('focus');
    },
    onEsc() {
      if (this.isClosed) return;
      this.close();
      this.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

$dropdown-border-color: #b2b2b2;

$dropdown: '.dropdown';

#{$dropdown} {
  display: inline-block;
  margin-bottom: 12px;
  position: relative;
  overflow: visible;
  user-select: none;

  &--no-margin-bottom {
    margin-bottom: 0;
  }

  @include mq($mq-large) {
    margin-bottom: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__button {
    display: block;
    @include font-size(18px, 27px);
    min-height: 36px;
    padding: 3px 30px 4px 12px;
    position: relative;
    text-align: left;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    transition-property: color, background-color;

    &:focus {
      background: color(grey, 12000);
      color: color(white);
    }
  }

  &__container {
    display: block;
    height: auto;
    left: 0;
    margin-top: 12px;
    margin-left: $grid-medium-gutter * 0.5;
    margin-right: $grid-medium-gutter * 0.5;
    position: absolute;
    right: 0;
    visibility: visible;
    z-index: 1;
  }

  &--multi {
    border: 0;
  }

  &--open {
    #{$dropdown}__button {
      &,
      &:hover,
      &:focus,
      &:active {
        background: color(grey, 12000);
        color: color(white);
      }
    }
  }

  &--search {
    padding: 0;

    #{$dropdown}__button {
      height: 100%;
      border-radius: 8px 0px 0px 8px;
      border: none;
    }

    #{$dropdown}__container {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
