<template>
  <div class="dropdown-item-content">
    <div class="dropdown-item-content__label">
      <slot>{{ label }}</slot>
    </div>
    <div
      v-if="hasCount"
      class="dropdown-item-content__count"
    >
      {{ count }}
    </div>
  </div>
</template>

<script>
import { isNull, isUndefined } from 'lodash-es';

export default {
  name: 'DropdownItemContent',
  props: {
    label: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: null,
    },
  },
  computed: {
    hasCount() {
      return !isNull(this.count) && !isUndefined(this.count);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/base';

.dropdown-item-content {
  color: color(grey, 13500);
  padding: 9px 12px;
  text-decoration: none;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  .dropdown-item--active & {
    color: color(white);
  }

  .dropdown-item__color-preview + & {
    padding-left: 10px;
  }

  &__label {
    width: 85%;
  }

  &__count {
    @include font-size(12px, 18px);
    width: 15%;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
